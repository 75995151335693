<template>
  <section class="howitworks">
    <div class="howitworks__row">
      <div class="howitworks__col">
        <h2>{{ title }}</h2>
        <p>{{ body }}</p>
      </div>
      <div class="howitworks__col">
        <ul>
          <li
            v-for="(item, i) in documentItems"
            :key="`item-${i}`"
            class="text-base"
          >
            <span class="text-lg bold">{{ item.title }}</span>
            <OsRichTextRenderer
              v-if="item.description"
              name="block"
              :content="item.description"
            />
          </li>
        </ul>
        <span class="space"></span>
      </div>
    </div>
    <div class="howitworks__car">
      <NuxtPicture
        class="howitworks__car__image"
        :provider="imageUrl.startsWith('/images/') ? '' : 'BBD'"
        sizes="sm:800px md:400px"
        :width="580"
        :height="360"
        :src="imageUrl"
        alt="How it works image"
        format="webp,png"
        loading="lazy"
      />

      <div class="howitworks__actions">
        <NuxtLink
          class="inline-link"
          :to="faqRoute"
          target="_blank"
          @click="trackClickEvent"
        >
          View Frequently asked questions
          <OsIcon name="Arrow" class="text-blue-600" />
        </NuxtLink>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { BLOCKS } from '@contentful/rich-text-types'
import { useFiltersStore } from '~/stores/filtersStore'
import { ERoutePageSlugs, ERoutes } from '~/types/routes'
import { IFilterDataBrandModel } from '~/types/filterData'
import { useFilterDataStore } from '~/stores/filterDataStore'
import { CmsModelImages, CmsShortContentBlock } from '~/types/cms/contentModel'

const isContentBlock = (
  data: CmsShortContentBlock | CmsModelImages
): data is CmsShortContentBlock =>
  Object.prototype.hasOwnProperty.call(data, 'description')

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    body: {
      type: String,
      required: true
    },
    isNew: {
      type: Boolean,
      required: true
    },
    data: {
      type: Array as PropType<(CmsShortContentBlock | CmsModelImages)[]>,
      required: true
    }
  },
  setup(props) {
    const { $dataLayer } = useNuxtApp()
    const filtersStore = useFiltersStore()
    const filterDataStore = useFilterDataStore()

    // Filter our document type items
    const documentItems = computed(() =>
      props.data.filter<CmsShortContentBlock>(
        (data): data is CmsShortContentBlock =>
          isContentBlock(data) && data.description?.nodeType === BLOCKS.DOCUMENT
      )
    )

    // Filter our ModelImage items
    const imageURLs = computed(() =>
      props.data.filter<CmsModelImages>(
        (data): data is CmsModelImages =>
          !isContentBlock(data) && !!data.imageUrlBbd
      )
    )

    const imageUrl = computed<string>(() => {
      const modelId = filtersStore.active.models[0]
      const selectedModel = filterDataStore.getModelById(
        modelId
      ) as IFilterDataBrandModel

      if (selectedModel && selectedModel.BbdUrl) {
        return selectedModel.BbdUrl
      }

      // Return some fallback if not set in CMS
      if (!imageURLs.value.length)
        return '/images/vehicle/defaults/missing-model-nobg.png'

      // No model selected, use default model image from CMS
      return props.isNew
        ? imageURLs.value[0].imageUrlBbd!
        : (imageURLs.value[1] && imageURLs.value[1].imageUrlBbd) ||
            imageURLs.value[0].imageUrlBbd!
    })

    const faqRoute = computed(() => {
      return {
        name: ERoutes.Page,
        params: {
          slug: ERoutePageSlugs.FAQ
        }
      }
    })

    const trackClickEvent = () => {
      $dataLayer.linkClick({
        pageCategory: 'how it works',
        category: 'how it works',
        action: 'faq'
      })
    }

    return {
      imageUrl,
      faqRoute,
      documentItems,
      trackClickEvent
    }
  }
}
</script>

<style lang="scss">
.howitworks {
  position: relative;
  border-top: none !important;
  .howitworks__row {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: rem(16);
    width: 100%;
    background: $grey--lightest;
    border-radius: 6px;
    padding: rem(40) 0 rem(40) 0;
    .howitworks__col {
      color: $black--light;
      padding: 0 rem(40) 0 rem(40);
      p {
        margin-top: rem(8);
      }
      .space {
        display: block;
        width: 100%;
        aspect-ratio: 4 / 1;
        @include viewport('md') {
          display: none;
        }
      }
    }

    @include viewport('md') {
      grid-template-columns: 1fr 1fr;
    }
  }

  ul {
    padding: 0;
    list-style-type: none;
    li {
      margin-top: rem(40);
      &:last-child {
        margin-bottom: rem(0);
      }
    }
    p {
      color: $grey--darkest;
    }
  }

  .howitworks__title {
    margin-bottom: rem(24);
  }

  .howitworks__car {
    display: flex;
    flex-direction: column;
    margin-top: -30%;
    .howitworks__car__image {
      width: 100%;
      position: relative;
      @include viewport('md') {
        width: 50%;
        position: absolute;
        bottom: rem(-56);
        max-width: 580px;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    @include viewport('md') {
      margin-top: 5%;
      right: 0;
    }
    .howitworks__actions {
      text-align: right;
      margin-top: rem(24);
      margin-right: rem(0);
      font-size: rem(18);
      color: $blue;
      svg {
        transform: rotate(-90deg);
        margin: 0 rem(12) 0 0;
      }
    }
  }
}
</style>
